// src/pages/SolutionsPage.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import styles from './SolutionsPage.module.css';
//import the image of the pci from the images folder
import PCI_Image from '../../assets/images/PCI_Image.png';
import IRI_Image from '../../assets/images/IRI_Image_New.png';
import WorkZone_Image from '../../assets/images/WorkZone_Image.jpg';

const SolutionsPage = () => {
  return (
    <div>
      <div className={styles.solutionsJumbotron}>
        <Container>
          <Row>
            <Col>
              <h1 className="display-4 text-center">Our Services</h1>
              <p className="lead text-center">Discover the innovative solutions we offer for road quality assessment and management.</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col lg={6} className="mb-4">
            <Card className={styles.serviceCard}>
              <Card.Img variant="top" src={IRI_Image} className={styles.imageSize}/>
              <Card.Body>
                <Card.Title>IRI Analysis</Card.Title>
                <Card.Text>
                  Our IRI Analysis service uses sensor data from mobile devices to assess road quality. The data is analyzed by our advanced software, providing accurate and reliable insights on road conditions for better decision-making.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} className="mb-4">
            <Card className={styles.serviceCard}>
              <Card.Img variant="top" src={PCI_Image} className={styles.imageSize} />
              <Card.Body>
                <Card.Title>PCI Analysis</Card.Title>
                <Card.Text>
                  Our PCI Analysis service utilizes video data captured by mobile devices to evaluate pavement conditions. The footage is processed by advanced deep-learning algorithms, which offer a comprehensive understanding of pavement deterioration and required maintenance.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} className="mb-4">
            <Card className={styles.serviceCard}>
              <Card.Img variant="top" src={WorkZone_Image} className={styles.imageSize} />
              <Card.Body>
                <Card.Title>Work Zone Detection</Card.Title>
                <Card.Text>
                  Our Work Zone Detection system provides real-time tracking and monitoring of workers in construction zones. Using advanced tracking technology, we help ensure worker safety by accurately determining when personnel are present in work zones, enabling better safety protocols and management decisions.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SolutionsPage;
