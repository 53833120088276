// src/pages/ResearchPage.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import styles from './ResearchPage.module.css';

const ResearchPage = () => {
  return (
    <div>
      <div className={styles.researchJumbotron}>
        <Container>
          <Row>
            <Col>
              <h1 className="display-4 text-center">Research</h1>
              <p className="lead text-center">Explore the research behind our cutting-edge technology.</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <h2 className="text-center mb-4">Collaboration with Prof. Yaw Adu-Gyamfi</h2>
            <p>
              Our company collaborates with Prof. Yaw Adu-Gyamfi, a leading researcher in the fields of transportation engineering, pavement engineering, and nondestructive testing. Prof. Adu-Gyamfi's expertise in these areas, along with his experience as an Assistant Professor at the University of Missouri, has contributed significantly to the development of our innovative solutions.
            </p>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={6}>
            <Card className={styles.researchCard}>
              <Card.Img variant="top" src="https://via.placeholder.com/150" />
              <Card.Body>
                <Card.Title>IRI Analysis</Card.Title>
                <Card.Text>
                  Our IRI Analysis software uses mobile sensor data to assess road quality, providing accurate and reliable insights. This technology is built upon the groundbreaking research conducted by Prof. Yaw Adu-Gyamfi.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className={styles.researchCard}>
              <Card.Img variant="top" src="https://via.placeholder.com/150" />
              <Card.Body>
                <Card.Title>PCI Analysis</Card.Title>
                <Card.Text>
                  Our PCI Analysis software leverages video data captured by mobile devices for pavement condition evaluation. The techniques and algorithms developed by Prof. Yaw Adu-Gyamfi form the basis of this innovative solution.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResearchPage;
