// src/pages/SignInPage.js

import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import styles from './SignInPage.module.css';

const SignInPage = () => {
  return (
    <div className={styles.signInContainer}>
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div className={styles.signInCard}>
              <h2 className="text-center mb-4">Sign In</h2>
              <Form>
                <Form.Group controlId="signInEmail">
                  <Form.Label className="form-label">Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter your email" />
                </Form.Group>
                <Form.Group controlId="signInPassword">
                  <Form.Label className="form-label">Password</Form.Label>
                  <Form.Control type="password" placeholder="Enter your password" />
                </Form.Group>
                <Button className={styles.signInButton} type="submit">
                  Sign In
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignInPage;

