// src/components/Navbar/index.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Image, Dropdown } from 'react-bootstrap';
import styles from './Navbar.module.css';
import logo from '../../assets/icons/Favicon A.png';


const Navigation = () => {
  const navigate = useNavigate();

  const handleSignIn = () => {
    navigate('/signin');
  };

  const handleSignUp = () => {
    navigate('/signup');
  };

  return (
    <Navbar expand="lg" className={styles.navbar}>
      <Container fluid>
        <Navbar.Brand>
          <Image src={logo} alt="Roadlytics" className={styles.logo} />
          <span className={styles.companyName}>Roadlytics</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/solutions">Solutions</Nav.Link>
            {/*
            <Nav.Link as={Link} to="/research">Research</Nav.Link>
            */}
            <Nav.Link as={Link} to="/about">About</Nav.Link>
            {/* 
            <Nav.Link as={Link} to="/contact">Contact Us</Nav.Link>
            */}
          </Nav>
          {/* 
          <Dropdown variant="primary" alignRight>
            <Dropdown.Toggle variant="primary">
              Account
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleSignIn}>Sign In</Dropdown.Item>
              <Dropdown.Item onClick={handleSignUp}>Sign Up</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
