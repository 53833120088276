// src/components/Footer.js

import React from 'react';
import styles from './Footer.module.css';
import { Container } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <p>&copy; {new Date().getFullYear()} Roadlytics. All rights reserved.</p>
      </Container>
    </footer>
  );
};

export default Footer;
