// src/pages/ContactUsPage.js
import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import styles from './ContactUsPage.module.css';

const ContactUsPage = () => {
  return (
    <div>
      <div className={styles.contactUsJumbotron}>
        <Container>
          <Row>
            <Col>
              <h1 className="display-4 text-center">Contact Us</h1>
              <p className="lead text-center">Get in touch with us for any questions or inquiries.</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <Form className={styles.contactForm}>
              <Form.Group controlId="contactName">
                <Form.Label className={styles.formLabel}>Name</Form.Label>
                <Form.Control className={styles.formControl} type="text" placeholder="Enter your name" />
              </Form.Group>
              <Form.Group controlId="contactEmail">
                <Form.Label className={styles.formLabel}>Email address</Form.Label>
                <Form.Control className={styles.formControl} type="email" placeholder="Enter your email" />
              </Form.Group>
              <Form.Group controlId="contactMessage">
                <Form.Label className={styles.formLabel}>Message</Form.Label>
                <Form.Control className={styles.formControl} as="textarea" rows={3} placeholder="Enter your message" />
              </Form.Group>
              <Button className={styles.submitButton} type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUsPage;
