import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './HomePage.module.css';
import splashScreen from '../../assets/images/splash_screen.png';

const HomePage = () => {
  return (
    <div className={styles.videoContainer}>
      <img src={splashScreen} alt="background" className={styles.video} width="100%" height="auto" style={{ filter: 'brightness(0.5)' }}/>
        <Container className={styles.textContainer}>
            <h1 className="display-1 text-center" style={{color: "#14649A", fontSize: "5rem", fontWeight: "bold"}}>
                Roadlytics
            </h1>
            <h2 className="display-4 text-center text-white">Automated Transportation Analytics</h2>
        </Container>
    </div>
  );
};

export default HomePage;


/* 
OLD CODE USED FOR VIDEO BACKGROUND

import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './HomePage.module.css';

const HomePage = () => {
  return (
    <div className={styles.videoContainer}>
      <video autoPlay muted loop className={styles.video} width="100%" height="auto" style={{ filter: 'brightness(0.5)' }}>
      <source src="/videos/topView.mp4" type="video/mp4" />
      </video>
        <Container className={styles.textContainer}>
            <h1 className="display-1 text-center" style={{color: "#14649A", fontSize: "5rem", fontWeight: "bold"}}>
                Roadlytics
            </h1>
            <h2 className="display-4 text-center text-white">Automated Road Inspection</h2>
        </Container>
    </div>
  );
};

export default HomePage;

*/
