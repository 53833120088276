// src/pages/AboutPage.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './AboutPage.module.css';

const AboutPage = () => {
  return (
    <div>
      <div className={styles.aboutJumbotron}>
        <Container>
          <Row>
            <Col>
              <h1 className="display-4 text-center">About Us</h1>
              <p className="lead text-center">Learn more about our company and its mission.</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <h2 className="text-center mb-4">Our Mission</h2>
            <p>
              Our mission is to revolutionize the way road quality and pavement conditions are assessed and managed. We strive to provide innovative, accurate, and efficient solutions for governments, municipalities, and infrastructure managers around the world.
            </p>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={6}>
            <h3>Our History</h3>
            <p>
              Founded in 2022, our company began with a simple idea: to harness the power of modern technology to improve the way road infrastructure is managed. Over the years, we have grown and evolved, continuously expanding our services and refining our solutions to meet the ever-changing needs of our clients.
            </p>
          </Col>
          <Col md={6}>
            <h3>Our Team</h3>
            <p>
              Our team is comprised of industry experts, talented engineers, and dedicated professionals who are passionate about making a difference. Together, we work tirelessly to develop cutting-edge technologies and provide exceptional service to our clients.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutPage;
