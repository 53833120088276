import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import CollectionsPage from './pages/CollectionsPage';
import MapViewPage from './pages/MapViewPage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import HomePage from "./pages/HomePage";
import SolutionsPage from "./pages/SolutionsPage";
import Footer from "./components/Footer";
import ResearchPage from "./pages/ResearchPage";
import AboutPage from "./pages/AboutPage";
import ContactUsPage from "./pages/ContactUsPage";


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} exact />
        <Route path="/solutions" element={<SolutionsPage />} exact />
        <Route path="/collections" element={<CollectionsPage />} exact />
        <Route path="/research" element={<ResearchPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="/map" element={<MapViewPage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

